.root {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
	padding-top: 26px;

}

.head {
	display: flex;
	align-items: center;
	padding-bottom: 43px;

	h1 {
		font-family: var(--font-lora);
		font-size: 64px;
		line-height: 75px;
		letter-spacing: -0.02em;
		color: #FFFFFF;
		margin-bottom: 0;
	}
}

.searchWrapper {
	width: 782px;
	position: relative;
	margin-left: auto;


	.searchIcon {
		position: absolute;
		right: 32px;
		top: 14px;
	}
}

.searchInp {
	width: 100%;
	background: #3B3060;
	box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	height: 52px;
	font-size: 16px;
	line-height: 24px;
	padding: 0 32px;
	letter-spacing: 0.47px;
	color: #CFCFCF;
	outline: none;
	border: 2px solid transparent;
	transition: .3s;

	&:focus, &:hover {
		border-color: #CC78FF;
	}
}

.blogTitleMobile {
	font-family: var(--font-lora);
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.47px;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	&:after {
		display: block;
		content: "";
		flex-grow: 1;
		height: 2px;
		margin-left: 15px;
		background: linear-gradient(90deg, #9E00FF 9.78%, rgba(158, 0, 255, 0) 87.17%);
	}
}

.currentBlogWrapper {
	display: flex;

	.mobile & {
		flex-direction: column;

		.currentBlogImage {
			width: 100%;
			height: auto;
			margin-bottom: 10px;
		}
		.currentBlogPublish {
			font-size: 12px;
			line-height: 17px;
		}
		.currentBlogTitle {
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 4px;
		}
	}

	.currentBlogImage {
		margin-right: 39px;
		border-radius: 8px;
	}

	.currentBlog {
		display: flex;
		flex-direction: column;
		color: #FFFFFF;
	}

	.currentBlogTitle {
		font-family: var(--font-lora);
		font-size: 40px;
		line-height: 47px;
		letter-spacing: 0.47px;
		margin-bottom: 21px;
		color: #FFFFFF;
	}

	.currentBlogPublish {
		font-family: var(--font-poppins);
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.256951px;
		margin-bottom: 6px;
	}

	.currentBlogSub {
		font-family: var(--font-poppins);
		font-weight: 600;
		font-size: 24px;
		line-height: 128%;
		letter-spacing: 0.256951px;
		color: #FFFFFF;
		margin-bottom: 17px;
	}

	.currentBlogDesc {
		font-family: var(--font-poppins);
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.47px;
		color: #CFCFCF;
	}
}

.allArticles {
	display: flex;
	flex-direction: column;
	margin-top: 84px;
	width: calc(100% + 48px);

	.mobile & {
		width: 100%;
		margin-left: 0;
		margin-top: 24px;

		.articleHead {
			padding: 0;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.47px;
			margin-bottom: 16px;

			.line {
				margin-left: 15px;
			}
		}
	}

	.articleHead {
		display: flex;
		align-items: center;
		font-family: var(--font-lora);
		font-size: 40px;
		line-height: 64px;
		letter-spacing: 0.0583333px;
		color: #FFFFFF;
		padding: 0 23px 46px;

		.line {
			flex-grow: 1;
			height: 2px;
			margin-left: 47px;
			background: linear-gradient(90deg, #9E00FF 9.78%, rgba(158, 0, 255, 0) 87.17%);
		}
	}
}

.overBlogItems {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 23px;

	.mobile & {
		justify-content: space-evenly;
		.blogItem {
			margin: 0;
			display: flex;
			width: 350px;
			max-width: none;
		}

		.blogItemImgLink {
			width: 50%;
			display: block;
			padding-right: 8px;
			flex-shrink: 1;
		}
		.blogItemImg {
			width: 100%;
			height: 166px;
			object-fit: cover;
		}
		.blogItemDate {
			font-size: 9px;
			line-height: 13px;
		}
		.blogItemTitle {
			font-size: 16px;
			font-family:  var(--font-poppins);
			line-height: 128%;
			margin-bottom: 0;
		}
		.blogItemDesc {
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.47px;
			max-height: 18px * 5;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}
	}
	.restMobile {
		width: 50%;
		display: flex;
		flex-direction: column;
		padding-left: 8px;
	}
	.blogItem {
		width: 395px;
		max-width: calc(#{100%} / 3);
		min-width: 300px;
	}

	.blogItemImg {
		margin-bottom: 23px;
		border-radius: 8px;
		width: 100%;
	}

	.blogItemDate {
		font-family: var(--font-poppins);
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.256951px;
		margin-bottom: 6px;
	}

	.blogItemTitle {
		font-family: var(--font-poppins);
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 128%;
		letter-spacing: 0.256951px;
		color: #FFFFFF;
		margin-bottom: 16px;
	}

	.blogItemDesc {
		font-family: var(--font-poppins);
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.47px;
		color: #CFCFCF;
		margin-bottom: 20px;
	}

	.tags {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.tag {
		background: #3B3060;
		border-radius: 13px;
		min-width: calc(50% - 6px);
		height: 34px;
		font-size: 18px;
		line-height: 26px;
		padding: 4px 14px;
		letter-spacing: 0.0583333px;
		color: #CFCFCF;
		margin-bottom: 12px;
	}

	.smallTag {
		min-width: auto;
		margin-left: 12px;
		margin-right: auto;
	}
}
